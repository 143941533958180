.container {
  height: 100dvh;
  overflow-y: auto;
  padding-left: 40px;
  padding-right: 40px;
}
@media (max-width: 48em) {
  .container {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.wrapper::-webkit-scrollbar {
  display: none; /* Chrome/Safari/Opera用 */
}
@media (max-width: 48em) {
  .wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.inner {
  justify-content: space-between;
  flex-wrap: nowrap;
  max-width: 100%;
}
@media (max-width: 48em) {
  .inner {
    flex-direction: column-reverse;
  }
}
.image {
  flex: 1;
  max-width: 500px;
  object-fit: contain;
}

@media (max-width: 48em) {
  .image {
    display: none;
  }
}
